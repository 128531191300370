<template>
  <div id="ProducerHomepage" :style="computedPageStyle">
    <qs-db-selector v-if="!hasTag('ED360')" style="grid-column: 1 / -1" />

    <qs-kpi-grid
      style="grid-column: 1 / span 13"
      title="Votre activité récente"
      :qlik-ids="['aPLkdAQ', 'MLxWVH', 'vxfAJ', 'zNsm', 'PtbyG', 'XVKSbT']"
      tooltip="Quelques indicateurs clef de votre activité récente, comparés à la même période de l'année précédente."
    />

    <QsTabs
      :qlik-ids="['JqHTVpW', 'bBChmB', 'VFKuMyZ']"
      style="grid-column: 14 / -1"
    />

    <qs-kpi-grid
      v-if="hasTag('access_control_producer')"
      style="grid-column: 1 / span 24; height: 220px"
      title="Participation"
      :qlik-ids="['JJjfTw', 'VBrdPuf', 'cjJQBj']"
      :columns="3"
      tooltip="Quelques indicateurs clef du contrôle d'accès, comparés à la même période de l'année précédente."
    />

    <qs-kpi-grid
      v-if="hasTag('access_control_museum') || hasTag('access_control_sport')"
      style="grid-column: 1 / span 24; height: 220px"
      title="Participation"
      :qlik-ids="['Xru', 'XqQmxf', 'ZkEUNKX']"
      :columns="3"
      tooltip="Quelques indicateurs clef du contrôle d'accès, comparés à la même période de l'année précédente."
    />

    <qs-kpi-grid
      v-if="hasTag('access_control_festival')"
      style="grid-column: 1 / span 24; height: 220px"
      title="Participation"
      :qlik-ids="['JJjfTw', 'VBrdPuf', 'cjJQBj']"
      :columns="3"
      tooltip="Indicateurs clef du contrôle d'accès sur les trois dernières années"
    />
  </div>
</template>

<script>
import QsKpiGrid from "@/components/Qlik/QsKpiGrid";
import QsDbSelector from "@/components/Qlik/QsDbSelector";
import QsTabs from "@/components/Qlik/QsTabs";
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("qlik");

export default {
  name: "ProducerHomepage",
  components: { QsKpiGrid, QsDbSelector, QsTabs },
  computed: {
    ...mapGetters(["hasTag"]),
    computedPageStyle() {
      return this.hasTag("ED360")
        ? "grid-template-rows: 500px;"
        : "grid-template-rows: auto 500px;";
    }
  }
};
</script>

<style scoped>
#ProducerHomepage {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>

<template>
  <el-card v-if="dbList[1]" shadow="never">
    <div slot="header">
      <b>Base de données sources</b>
      <el-tooltip placement="bottom" style="float: right" effect="light">
        <div slot="content">
          <el-button class="legend-item" type="primary" size="mini">
            Filtre actif
          </el-button>
          <br />
          <el-button class="legend-item" type="default" size="mini">
            Disponible avec les filtres actuels
          </el-button>
          <br />
          <el-button class="legend-item" type="info" size="mini">
            Indisponible avec les filtres actuels
          </el-button>
          <br />
          <span class="legend-item">
            <fa :icon="['fal', 'exclamation-triangle']" />
            Tout clic sur un bouton grisé (indisponible) écrasera tous les
            filtres créés.
          </span>
        </div>
        <span><fa :icon="['fal', 'info-circle']" /></span>
      </el-tooltip>
    </div>
    <div style="text-align: center">
      <el-button
        v-for="db in dbList"
        :key="db.qElemNumber"
        style="margin: 5px"
        @click="toggleSelectDb(db.qText)"
        :type="db.enabled ? 'primary' : db.qState !== 'O' ? 'info' : 'default'"
      >
        {{ db.qText }}
      </el-button>
    </div>
  </el-card>
  <div v-else></div>
</template>

<script>
import enigma from "enigma.js";
import schema from "enigma.js/schemas/12.20.0.json";
import qlikConfig from "@/config/qlik.js";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("qlik");

const dbListQuery = {
  qInfo: { qId: "sourceDatabaseList", qType: "ListObject" },
  qListObjectDef: {
    qDef: {
      qFieldDefs: ["=[Base de données source]"],
      qFieldLabels: ["Base de données source"],
      qSortCriterias: [{ qSortByAscii: 1 }]
    },
    qInitialDataFetch: [{ qHeight: 99, qLeft: 0, qTop: 0, qWidth: 1 }],
    qShowAlternatives: true,
    qStateName: "dbSelector"
  }
};

export default {
  props: ["qlikId"],
  data() {
    return {
      enigmaSession: null,
      qlikDoc: null,
      error: null,
      dbList: [],
      dbListObjectHandle: null
    };
  },
  async mounted() {
    const { host, prefix } = qlikConfig;
    this.enigmaSession = enigma.create({
      schema,
      url: `wss://${host}${prefix}app/${this.app.qDocId}`,
      createSocket: url => new WebSocket(url)
    });

    const global = await this.enigmaSession.open().catch(this.handleError);
    this.qlikDoc = await global
      .openDoc(this.app.qDocId)
      .catch(this.handleError);
    this.getDbList(true);
  },
  beforeDestroy() {
    this.enigmaSession.close();
  },
  computed: mapState(["app"]),
  methods: {
    async getDbList(initialFetch = false) {
      this.dbList = await this.qlikDoc
        .createSessionObject(dbListQuery)
        .then(dbListObject => {
          this.dbListObjectHandle = dbListObject.handle;
          initialFetch &&
            this.enigmaSession.on("traffic:received", ({ change = [] }) => {
              change.includes(this.dbListObjectHandle) && this.getDbList();
            });
          return dbListObject.getLayout();
        })
        .then(({ qListObject: { qDataPages } }) =>
          qDataPages[0].qMatrix.map(db => ({
            ...db[0],
            enabled: db[0].qState === "S"
          }))
        )
        .catch(this.handleError);
    },
    toggleSelectDb(db) {
      this.$mixpanel.track("Click source database key indicators");
      this.qlikDoc
        .getField("Base de données source")
        .then(field => field.toggleSelect(db));
    },
    handleError(error) {
      this.error = "Une erreur inattendue est survenue.";
      throw new Error(error);
    }
  }
};
</script>

<style scoped>
.legend-item {
  margin: 2px 0;
}
</style>
